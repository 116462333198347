.Page-container {
    font-family: 'Sans Serif';
    max-width: 800px;
    margin: 20px auto;
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    line-height: 1.6;
    text-align: left;
    color: black;
}

.outerPageContainer {
    background-color: rgba(218, 218, 218, 0.378);
    width: 80%;
    margin: auto;
    min-height: 95vh;
    overflow-y: auto;
    padding-bottom: 20px;
}

.Southwest-h1 {
    font-size: 2rem;
    color: black;
    text-align: center;
    margin-bottom: 10px;
}

.Southwest-container {
    margin-top: 20px;
}

.SwForm {
    display: flex;
    flex-direction: column;
    gap: 15px;
    justify-content: flex-start;
}

label {
    font-weight: bold;
    margin-bottom: 5px;
}

.formUnit {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    min-width: 200px;
}

.formUnit label {
    margin-right: 8px;
}

.SwInput {
    flex-grow: 1;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 1rem;
    margin-bottom: 10px;
}

input[type="date"]::placeholder {
    color: blue; /* Light gray color for the placeholder text */
    opacity: 1; /* Ensures the color is applied properly */
}

.SwRadio {
    margin-left: 10px;
    margin-right: 5px;
    color: black;
}

.swButton {
    background-color: #ffb100;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
    align-self: flex-start;
    margin-left: 10px;
}

.swButton:hover {
    background-color: #e09b00;
}

fieldset {
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 15px;
    margin-bottom: 20px;
}

legend {
    font-weight: bold;
    color: black;
}
