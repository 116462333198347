.homeContainer {
    background-color: rgba(218, 218, 218, 0.378);
    width: 80%;
    margin: auto;
    overflow: auto;
    min-height: 95vh;
    font-family: 'Sans Serif';
    font-size: 20px;
}

.homeH1 {
    margin: 0px 0px 15px 0px;
    padding: 25px;
    font-size: 50px;
    font-weight: 500;
    background-color: rgba(173, 173, 173, 0.75);
    border-radius: 6px;
}

.homeDiv {
    width: 50%;
    text-align: left;
    margin: auto;
}

p {
    color: black;
}
