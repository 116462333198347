.navContainer {
    display: flex;
    background-color: rgb(0, 0, 0);
    justify-content: space-between;
    color: white;
    max-height: 3em;
    font-family: 'Arial';
}

.navH2, .navNav {
    align-self: center;
    padding: 10px;
    margin: 0px 10px;
}

.navH2 {
    font-weight: 600;
}

.navUl {
    display: flex;
    flex-direction: row;
    margin: 0px;
    list-style-type: none;
}

.navUl li a {
    margin: 20px;
    color: white;
    text-decoration: none;
}

.navUl li a:hover {
    text-decoration: underline;
}

.dropdown {
    position: relative;
}

.dropdownItems {
    position: absolute;
    list-style-type: none;
    z-index: 1;
    width: 100%;
    background-color: black;
    text-align: center;
    padding: 0px;
    margin: 0px;
    display: none;
}

.dropdown li a {
    color: white;
    margin: auto;
}

.dropdown li {
    padding: 5px 0px;
}

.dropdown li:first-child {
    padding-top: 15px;
    padding-bottom: 0px;
}

.dropdown:hover .dropdownItems {
    display: block;
} 

.dropdownItems li:hover {
    background-color: black;
}
