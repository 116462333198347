.pageContainer {
    height: 95vh;
    display: flex;
    flex-direction: column;
    background-color: rgba(218, 218, 218, 0.378);
    width: 80%;
    margin: auto;
}

.contactContainer {
    text-align: center;
    background-color: white;
    padding: 20px;
    width: 500px;
    margin: auto;
    border-radius: 15px;
    box-shadow: 5px 5px 10px rgb(60, 59, 59);
}

.contactH1 {
    margin: 0px 0px 15px 0px;
    padding: 25px;
    font-size: 50px;
    font-weight: 500;
    background-color: rgba(173, 173, 173, 0.75);
    border-radius: 6px;
}

.form {
    display: flex;
    flex-direction: column;
    justify-content: left;
    font-family: 'Sans Serif';
}

label {
    text-align: left;
}

.form input, .form textarea {
    margin-bottom: 10px;
    font-family: inherit;
    border-style: inset;
    border-width: 1px;
    width: 95%;
}

.animation {
    animation: fall-down 2s;
}

@keyframes fall-down {
    from {
        transform: translateY(-100%);
    }
    50% {
        transform: translateY(50px);
    }
    to {
        transform: translateY(0);
    }
}
