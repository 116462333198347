.projectsPageContainer {
    display: flex;
    flex-direction: column;
    width: 80%;
    background-color: rgba(218, 218, 218, 0.378);
    overflow-y: auto;
    min-height: 95vh;
    margin: auto;
}

.projectsContainer {
    width: 75%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    margin: auto;
    padding-top: 20px;
}

.thesisContainer, .scraperContainer {
    flex-basis: 40%;
    margin: auto;
}

iframe {
    width: 349px;
    height: 280px;
}
.restPage {
    margin: auto;
    padding: 30px;
    width: 85%
}

img {
    width: 250px;
    height: 350px;
}

.projectsContainer h2 {
    font-size: 1.2em;
}

.projectsH2 {
    margin: 0px 0px 15px 0px;
    padding: 25px;
    font-size: 50px;
    font-weight: 500;
    background-color: rgba(173, 173, 173, 0.75);
    border-radius: 6px;
}
