.resumeContainer {
    display: flex;
    flex-direction: column;
    background-color: rgba(218, 218, 218, 0.378);
    width: 80%;
    margin: auto;
    min-height: 95vh;
    overflow-y: auto;
    padding-bottom: 20px;
}

div {
    text-align: center;
}

.iframeContainer {
    width: 500px;
    margin: auto;
    height: 700px;
    overflow-y: auto;
}

.iframe {
    border-radius: 5px;
    width: 100%;
    height: 100%;
}

.resumeH1 {
    background-color: rgba(173, 173, 173, 0.75);
    border-radius: 6px;
    width: 100%;
    font-size: 50px;
    font-weight: 500;
    margin: 0px 0px 15px 0px;
    padding: 25px 0px;
}

.resumeA{
    text-decoration: none;
    color: black;
}

.resumeA:hover{
    text-decoration: underline;
    color: rgb(23, 23, 115);
}
